/*
 * Program: MroForm.jsx
 * Application: Mro Category
 * Option: to Add/Update Mro Category Information
 * Developer: Chandra Shekhar
 * Date: 2022-04-01
 */
import React, { Component, createRef } from "react";
import { Form, Modal, Tab, Nav } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import MroCategoryService from "../../services/MroCategoryService";
import Alerts from "../common/Alerts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import PropertyService from "../../services/PropertyService";
import UserService from "../../services/UserService";
import MroService from "../../services/MroService";
import Moment from "moment";
import FileWidget from "../fileUploader/FileWidget";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import MroNotes from "./MroNotes";
import { Link } from "react-router-dom";
import back from "./../../assets/images/back.svg";
import placeholderImage from "./../../assets/images/image-placeholder.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "../CustomToolbar";
import LinkModal from "../LinkModal";
import DeleteImageModal from "../DeleteImageModal";
import ReactImageMagnify from "react-image-magnify";
export class MroForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.quillRef = React.createRef();
    this.state = {
      showModal: false,
      showModalImage: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      isDelete: false,
      sort_order: "",
      title: "",
      note: "",
      mro_category_slug: "",
      mroCategory: [],
      selectedMroCategory: [],
      property_slug: "",
      propertyList: [],
      selectedPropertyList: [],
      zone_type: "",
      zoneTypeList: [],
      selectedZoneTypeList: [],
      location_slug: "",
      locationList: [],
      selecteLocationList: [],
      priority_type: "",
      priorityType: [],
      selectedPriorityType: [],
      status_id: "",
      statusList: [],
      selectedStatusList: [],
      userList: [],
      selectedUserList: [],
      user_slug: '',
      due_date: "",
      newRelatedFilesDesc: [],
      activeClass: "info",
      isRefresh: false,
      mroNumber: "",
      createdBy: "",
      createdAt: "",
      inspectionNo: "",
      updatedBy: "",
      updatedAt: "",
      imageData: [],
      mroImages: [],
      deletedFileList: [],
    };
    this.imageRefs = [];
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateMro = this.saveOrUpdateMro.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.deleteMro = this.deleteMro.bind(this);
    this.deleteMroImage = this.deleteMroImage.bind(this);
    this.opendeleteMroModal = this.opendeleteMroModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.onCloseImagePop = this.onCloseImagePop.bind(this);
  }

  componentDidMount() {
    this.getMROData();
    // this.getUserList();
    this.getMroCategoryList();
    this.getPropertyList();
    if(this.state.slug === undefined){
    const quill = this.quillRef.current.getEditor();
    quill.getModule("toolbar").addHandler("link", this.handleLink);

    // Add event listener for clicks on links
    quill.root.addEventListener("click", this.handleLinkClick);
    }
  }

  componentWillUnmount() {
    if(this.state.slug === undefined){
      const quill = this.quillRef.current.getEditor();
      quill.root.removeEventListener("click", this.handleLinkClick);
    }
  }

  handleLink() {
    const quill = this.quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      this.setState({
        showModal: true,
        range,
        url: "",
        text: "",
        isEditing: false,
      });
    }
  }

  handleModalClose() {
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleModalSubmit(url, text) {
    const quill = this.quillRef.current.getEditor();
    const { range } = this.state;
    if (range && url && text) {
      quill.deleteText(range.index, range.length);
      quill.insertText(range.index, text, "link", url);
      quill.setSelection(range.index + text.length);
    }
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleLinkClick(event) {
    if (event.target.tagName === "A") {
      if (event.ctrlKey || event.metaKey) {
        // Open the URL in a new tab
        const url = event.target.getAttribute("href");
        window.open(url, "_blank");
      } else {
        event.preventDefault();
        const quill = this.quillRef.current.getEditor();
        const range = quill.getSelection();
        const url = event.target.getAttribute("href");
        const text = event.target.innerText;

        // Prevent Quill's tooltip from showing
        quill.blur();

        this.setState({
          showModal: true,
          range: { index: range.index, length: text.length },
          url,
          text,
          isEditing: true,
        });
      }
    }
  }

  handleModalRemove() {
    const quill = this.quillRef.current.getEditor();
    const textLength = quill.getLength();
    // let range = { index: 0, length: textLength };

    // Get the entire content and iterate over each character
    for (let i = 0; i < textLength; i++) {
      const [link] = quill.getFormat(i, 1).link || [];
      if (link) {
        // Remove the link format only, leaving the text intact
        quill.formatText(i, 1, "link", false);
      }
    }

    this.setState({ showModal: false, url: "", text: "", isEditing: false });
  }

  getActiveClass(e) {
    if (e === "notes") {
      this.setState({ isRefresh: true });
    } else {
      this.setState({ isRefresh: false });
    }
    this.setState({ activeClass: e });
  }

  // Get MRO Category List
  async getMroCategoryList(queryString = "") {
    this.setState({ loading: true });
    var mroCategoryList = [];
    var mroCategoryListData = [];
    let res = await MroCategoryService.getMroCategoryList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      mroCategoryList = res.data ? res.data : [];
    }
    if (mroCategoryList.length > 0) {
      mroCategoryList.forEach((item) => {
        mroCategoryListData.push({
          label: item.name,
          value: item.slug,
        });
      });
    }
    this.setState({ mroCategory: mroCategoryListData });
    this.setState({ loading: false });
  }

  // Get Property List
  async getPropertyList(queryString = "") {
    this.setState({ loading: true });
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });
    if (propertyList.length === 1) {
      this.getLocation("property_slug=" + propertyList[0].slug + "&zone_type=5");
      queryString = "property_slug=" + (propertyList[0] ? propertyList[0].slug : "");
      this.getUserList(queryString);
      this.setState({
          propertyList: propertyListData,
          propertyName: propertyList[0].property_name, // Set single property name
          property_slug: propertyList[0].slug, // Set single property slug
      });
    } else {
        this.setState({
            propertyList: propertyListData,
        });
    }
    this.setState({ loading: false });
  }

  // Get Users List
  async getUserList(queryString = "") {
    this.setState({ loading: true });
    var userList = [];
    var userListData = [];
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
      // if (item.role_id === 4 || item.role_id === 6) {
      userListData.push({
        label: item.full_name,
        value: item.slug,
      });
      //}
    });

    this.setState({ userList: userListData });
    this.setState({ loading: false });
  }

  // Get Location List
  async getLocation(queryString = "") {
    this.setState({ selecteLocationList: [] });
    this.setState({ loading: true });
    var locationList = [];
    var locationListData = [];
    let res = await MroService.getLocationsMobile(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationList = res && res.data.data ? res.data.data : res.data ? res.data : [];
    }
    locationList.forEach((item) => {
      locationListData.push({
        label: item.name,
        value: item.slug,
        zone_type_id: item.zone_type_id,
      });
    });
    this.setState({ locationList: locationListData });
    this.setState({ loading: false });
  }

  async getMROData() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await MroService.getMro(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.due_date = res.data.due_date
          ? Moment(res.data.due_date).toDate("dd-MM-YYYY")
          : null;
        resData.note = res.data.note;
        resData.mro_category_slug = res.data?.mro_category?.slug;
        resData.property_slug = res.data?.property?.slug;
        resData.user_slug = res.data?.user?.slug;
        resData.property = res.data?.property;
        if (resData.property) {
          this.setState({ propertyName: resData.property.property_name });
        }
        if (resData.mro_number) {
          this.setState({
            mroNumber: resData.mro_number,
            mroImages: resData.mro_images,
          });
        }
        if (resData.mro_images) {
          this.setState({ mroImages: resData.mro_images });
          this.imageRefs = resData.mro_images.map(() => createRef());
        }

        if (resData.mro_inspection) {
          if (resData.mro_inspection.get_inspection) {
            this.setState({
              inspectionNo:
                "INS" + resData.mro_inspection.get_inspection.inspection_no,
            });
          }
        }

        if (resData.created_at) {
          this.setState({
            createdAt: Moment(resData.convert_created_at).format(
              "DD MMM, YY - hh:mm A "
            ),
          });
        }
        if (resData.updated_at) {
          this.setState({
            updatedAt: Moment(resData.convert_updated_at).format(
              "DD MMM, YY - hh:mm A "
            ),
          });
        }

        if (resData.created_by) {
          this.setState({ createdBy: resData.created_by.full_name });
        }

        if (resData.updated_by) {
          this.setState({ updatedBy: resData.updated_by.full_name });
        }

        // MRO Category Select
        var selectedMroCategory = [];
        selectedMroCategory = res.data.mro_category
          ? [
              {
                value: res.data.mro_category.slug,
                label: res.data.mro_category.name,
              },
            ]
          : "";

        // Property Select
        var selectedProperty = [];
        selectedProperty = res.data.property
          ? [
              {
                value: res.data.property.slug,
                label: res.data.property.property_name,
              },
            ]
          : "";

        // Zone Type Select
        var selectedZoneType = [];
        var selectedLocation = [];
        let zoneType = parseInt(res.data.zone_type);
        global.zoneTypeList.forEach((item, i) => {
          if (parseInt(item.value) === zoneType) {
            selectedZoneType = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            //Location API Call here to get selected location
            if (zoneType === 1) {
              // Get Room List
              this.getLocation(
                "property_slug=" + res.data.property.slug + "&zone_type=1" + "&is_web=1"
              );
              selectedLocation = [
                {
                  value: res.data.room.slug,
                  label: res.data.room.room_name,
                },
              ];
              resData.location_slug = res.data.room.slug;
            } else if (zoneType === 2) {
              // Get Commercial Area List
              this.getLocation(
                "property_slug=" + res.data.property.slug + "&zone_type=2" + "&is_web=1"
              );
              selectedLocation = [
                {
                  value: res.data.commercial_zone.slug,
                  label: res.data.commercial_zone.commercials_zone_name,
                },
              ];
              resData.location_slug = res.data.commercial_zone.slug;
            } else if (zoneType === 3) {
              // Get Equipment Zone List
              this.getLocation(
                "property_slug=" + res.data.property.slug + "&zone_type=3" + "&is_web=1"
              );
              selectedLocation = [
                {
                  value: res.data.equipment_zone.slug,
                  label: res.data.equipment_zone.equipment_zone_name,
                },
              ];
              resData.location_slug = res.data.equipment_zone.slug;
            } else if (zoneType === 4) {
              // Get Equipment Zone List
              this.getLocation(
                "property_slug=" + res.data.property.slug + "&zone_type=4" + "&is_web=1"
              );
              selectedLocation = [
                {
                  value: res.data.equipment.slug,
                  label: res.data.equipment.equipment_name,
                },
              ];
              resData.location_slug = res.data.equipment.slug;
            } else if (zoneType === 5) {
              // Get All Zone List
              this.getLocation(
                "property_slug=" + res.data.property.slug + "&zone_type=5" + "&is_web=1"
              );
              selectedLocation = [
                {
                  value: '0',
                  label: 'All Zone',
                },
              ];
              resData.location_slug = "0";
            } 
            return false;
          }
        });

        // Priority  Select
        var selectedPriority = [];
        global.priorityType.forEach((item, i) => {
          if (parseInt(item.value) === parseInt(res.data.priority_type)) {
            selectedPriority = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });

        // Status  Select
        var selectedStatus = [];
        var mroStatuslistRoleWise = global.mroStatusList;
        mroStatuslistRoleWise.forEach((item, i) => {
          if (parseInt(item.value) === parseInt(res.data.status_id)) {
            selectedStatus = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });

        // Technician/User Select
        var selectedUser = [];
        selectedUser = res.data.user
          ? [
              {
                value: res.data.user.slug,
                label: res.data.user.full_name,
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({ selectedMroCategory: selectedMroCategory });
      this.setState({ selectedPropertyList: selectedProperty });
      this.setState({ selectedZoneTypeList: selectedZoneType });
      this.setState({ selectedPriorityType: selectedPriority });
      this.setState({ selectedStatusList: selectedStatus });
      this.setState({ selectedUserList: selectedUser });
      this.setState({ selecteLocationList: selectedLocation });
      this.setState({ input: resData });
      this.defaultData = resData;
      var queryString = "property_slug=" + (resData.property_slug ? resData.property_slug : "");
      this.getUserList(queryString);
    }

    this.setState({ zoneTypeList: global.zoneTypeList });
    this.setState({ priorityType: global.priorityType });
    this.setState({ statusList: global.mroStatusList });
    this.setState({ loading: false });
  }

  // Save and Update Method Start
  saveOrUpdateMro = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
    });
    //Image Section Start
    const FileWidgetObj = new FileWidget();
    this.setState({ loading: true, isSubmit: true });
    const output = await FileWidgetObj.fileUploadFun("mro_form");
    let inputFilesData = [];
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          inputFilesData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };
        }
      }
      if (this.validator.allValid() && this.customValidate()) {
        this.setState({ loading: true, isSubmit: true });
        let inputData = {
          title: this.state.title,
          note: this.state.note,
          mro_category_slug: this.state.mro_category_slug,
          zone_type: this.state.zone_type,
          property_slug:this.state.property_slug,
          priority_type: this.state.priority_type,
          user_slug: this.state.user_slug,
          due_date: this.state.due_date
            ? Moment(this.state.due_date).format("YYYY-MM-DD")
            : null,
          location_slug: this.state.location_slug,
          status_id: this.state.status_id,
          imageData: inputFilesData,
          deletedFiles: this.state.deletedFileList,
        };
        if (this.state.slug !== undefined) {
          this.setState({ loading: true, isSubmit: true });
          let res = await MroService.updateMro(this.state.slug, inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () => this.props.history.push("/mros"),
              global.redirect_time
            );
          } else {
            let alertMessage = "";
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: res.data.message ? res.data.message : "Error!",
              alertModalMessage:
                alertMessage !== "" ? alertMessage : res.data.message,
            });
          }
        } else {
          this.setState({ loading: true, isSubmit: true });
          let res = await MroService.createMro(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () => this.props.history.push("/mros"),
              global.redirect_time
            );
          } else {
            let alertMessage = "";
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: res.data.message ? res.data.message : "Error!",
              alertModalMessage:
                alertMessage !== "" ? alertMessage : res.data.message,
            });
          }
        }
        this.setState({ loading: false, isSubmit: false });
        setTimeout(
          () => this.setState({ showAlertModal: false }),
          global.alert_close_time
        );
      } else {
        this.setState({ loading: false, isSubmit: false });
        this.validator.showMessages();
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: output.response[0].validationMessage[0][0],
        loading: false,
        isSubmit: false,
      });
      setTimeout(
        () =>
          this.setState({
            showAlertModal: false,
            loading: false,
            isSubmit: false,
          }),
        global.alert_close_time
      );
    }
    //Image Section End
  };
  // Save and Update Method End

  opendeleteMroModal(slug) {
    this.setState({ deleteMroModal: true });
  }

  closedeleteMroModal() {
    this.setState({ deleteMroModal: false });
  }

  opendeleteAlertModal() {
    this.setState({ alertModal: true });
  }

  closeAlertModal() {
    this.setState({ alertModal: false });
  }

  // Delete Method Start
  async deleteMro(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await MroService.deleteMro(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteMroModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(() => this.props.history.push("/mros/"), global.redirect_time);
    } else {
      this.closedeleteMroModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  // Delete Method End

  // Delete MRO Image Start
  async deleteMroImage(slug) {
    this.setState({ loading: true, isSubmit: true });
    let res = await MroService.deleteMroImage(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        showModalImage: false,
      });
      this.getMROData();
      //setTimeout(() => this.props.history.push("/mros"), global.redirect_time);
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  // Delete MRO Image End

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    if (typeof value === "string") {
      // Remove HTML tags using a regular expression
      value = value.replace(/<\/?[^>]+(>|$)/g, "");
    }

    // Update the input object and state with the sanitized value
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Call the validation methods
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("customer_name");
      this.validator.showMessageFor("customer_logo");
    }
  }

  handleChange(value) {
    this.setState({ note: value }, () => {
      this.customValidate();
    });
  }
  // MRO Category Change Handler
  handleChangeMroCategory(value) {
    let mro_category_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        mro_category_slug.push(item.value);
      });
    }
    this.setState({
      selectedMroCategory: value,
      mro_category_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  // Zone Type Change Handler
  handleChangeZoneType(value) {
    let propertySlug = "";
    let zoneType = "";
    if (this.state.property_slug === null || this.state.property_slug === "") {
      // alert("Please select property first.");
      this.opendeleteAlertModal();
      return false;
    }
    propertySlug = this.state.property_slug;
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    this.setState({
      selectedZoneTypeList: value,
      zone_type: value.value,
    });
    setTimeout(() => {
      zoneType = this.state.zone_type;
      if (zoneType === "1") {
        // Get Room List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=1" + "&is_web=1");
        this.setState({ location_slug: '' });
      } else if (zoneType === "2") {
        // Get Commercial Area List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=2"+ "&is_web=1");
        this.setState({ location_slug: '' });
      } else if (zoneType === "3") {
        // Get Equipment Zone List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=3" + "&is_web=1");
        this.setState({ location_slug: '' });
      } else if (zoneType === "4") {
        // Get Equipment Zone List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=4" + "&is_web=1");
        this.setState({ location_slug: '' });
      } else if (zoneType === "5") {
        // Get Equipment Zone List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=5" + "&is_web=1");
        this.setState({ location_slug: '' });
      }
    }, 100);

    this.setState({ confirm_back: 1 });
  }

  // Priority  Change Handler
  handleChangePriority(value) {
    let priority_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        priority_type.push(item.value);
      });
    }
    this.setState({
      selectedPriorityType: value,
      priority_type: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  // Status Change Handler
  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  // Property Change Handler
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    // Zone Type Select
    let selectedZoneType = [];
    var selectedLocation = [];
    let zoneType = 5;
    global.zoneTypeList.forEach((item, i) => {
      if (parseInt(item.value) === zoneType) {
        selectedZoneType = [
          {
            value: item.value,
            label: item.label,
          },
        ];
        if (zoneType === 5) {
          // Get Equipment Zone List
          this.getLocation("property_slug=" + value.value + "&zone_type=5");
          selectedLocation = this.state.locationList;
        }
        return false;
      }
    });
    this.setState({
      selectedZoneTypeList: selectedZoneType,
      zone_type: selectedZoneType[0].value,
      selectedPropertyList: value,
      property_slug: value.value,
      selecteLocationList: [],
      locationList: selectedLocation,
    });
    var queryString = "property_slug=" + (value.value ? value.value : "");
    this.getUserList(queryString);
    this.setState({ confirm_back: 1 });
  }

  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  // Location Change Handler
  handleChangeLocation(value) {
    let location_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_slug.push(item.value);
      });
    }
    this.setState({
      selecteLocationList: value,
      location_slug: value.value,
      zone_type: value.zone_type_id
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  toggleModal(slug) {
    // this.setState({ showModalImage: !this.state.showModalImage, imageSlug:slug });
    this.setState({
      showModalImage: true,
      imageSlug: slug,
    });
  }
  onCloseImagePop() {
    this.setState({ showModalImage: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update MRO";
    } else {
      return "Add New MRO";
    }
  }
  hoverHandler = (image, i) => {
    this.setState({ img: image });
    this.imageRefs.forEach((ref, index) => {
      if (index === i) {
        ref.current.classList.add('active');
      } else {
        ref.current.classList.remove('active');
      }
    });
  };
  render() {
    const { img } = this.state;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    const modules = {
      toolbar: {
        container: "#toolbar",
        handlers: {
          link: this.handleLink,
        },
      },
    };
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle heading_alignment">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        MRO
                        <Link to="/mros">
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                      <span>
                        {this.state.slug !== undefined ? (
                          <>UPDATE MRO</>
                        ) : (
                          <>Add MRO</>
                        )}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
            {/* Nav Bar start */}
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="info"
              onSelect={(e) => this.getActiveClass(e)}
              eventKey={this.state.activeClass}
            >
              {this.state.slug !== undefined ? (
                <div className="scrolling-carousel pd-l-0">
                  <ScrollingCarousel rightArrow={true} leftArrow={true}>
                    <div className="swipesection ">
                      <Nav variant="pills" className="">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="info"
                            className={
                              this.state.activeClass === "info" ? "active" : ""
                            }
                          >
                            Info
                          </Nav.Link>
                        </Nav.Item>
                        {global.userPermissions.checkPermission(
                          "mros-notes"
                        ) && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="notes"
                              className={
                                this.state.activeClass === "notes"
                                  ? "active"
                                  : ""
                              }
                            >
                              Notes
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      </Nav>
                    </div>
                  </ScrollingCarousel>
                </div>
              ) : (
                ""
              )}
              <Tab.Content>
                <Tab.Pane eventKey="notes">
                  {this.state.isRefresh && (
                    <MroNotes mro_slug={this.state.slug} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="info">
                  <div className="col-md-12 mg-t-18 p-5 dashboardRightcard mb-5">
                    {this.state.slug !== undefined ? (
                      <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-80 pd-r-70">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-5">
                            MRO No.
                          </label>
                          <p>{this.state.mroNumber}</p>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label text-left mg-b-5">
                            Created By
                          </label>
                          <p>
                            {this.state.createdAt && this.state.createdBy
                              ? this.state.createdAt +
                                " by " +
                                this.state.createdBy
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label text-left mg-b-5">
                            Inspection No.
                          </label>
                          <p>{this.state.inspectionNo}</p>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label text-left mg-b-5">
                            Last Update
                          </label>
                          <p>
                            {this.state.updatedAt && this.state.updatedBy
                              ? this.state.updatedAt +
                                " by " +
                                this.state.updatedBy
                              : ""}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="tabNavigation">
                      <form
                        id="mro_form"
                        onSubmit={this.onSubmitFun}
                        method="post"
                        encType="multipart/form-data"
                      >
                        <div className="mg-t-0 mg-b-0">
                          <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                            {/* MRO Title */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Title{" "}
                              </label>
                              {/* MRO Title  */}
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="title"
                                value={
                                  this.state.title
                                    ? this.state.title.replace(
                                        /<\/?[^>]+(>|$)/g,
                                        ""
                                      )
                                    : ""
                                }
                                maxLength="50"
                                tabIndex="1"
                                autoComplete="off"
                              />
                              {this.state.errors.title ? (
                                <div className="text-danger">
                                  {this.state.errors.title}
                                </div>
                              ) : (
                                this.validator.message(
                                  "title",
                                  this.state.title,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* MRO Category */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Category (Optional){" "}
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedMroCategory}
                                options={this.state.mroCategory}
                                onChange={(value) =>
                                  this.handleChangeMroCategory(value)
                                }
                                defaultValue={this.state.selectedMroCategory}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                              {/* {this.state.errors.mro_category_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.mro_category_slug}
                                </div>
                              ) : (
                                this.validator.message(
                                  "category",
                                  this.state.mro_category_slug,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )} */}
                            </div>
                            {/* MRO Note  */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Notes {this.state.slug == undefined ? "(Optional)" : ""}
                              </label>
                              {/* <textarea
                                type="text"
                                as="textarea"
                                onChange={this.changeHandler}
                                name="note"
                                value={this.state.note}
                                maxLength="500"
                                tabIndex="1"
                                placeholder="Type here..."
                              /> */}
                              {/* {this.state.errors.note ? (
                                <div className="text-danger">
                                  {this.state.errors.note}
                                </div>
                              ) : (
                                this.validator.message(
                                  "note",
                                  this.state.note,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )} */}

                              {this.state.slug == undefined ? 
                              <>
                              <CustomToolbar />
                              <ReactQuill
                                ref={this.quillRef}
                                theme="snow"
                                name="note"
                                value={this.state.note ?? ""}
                                onChange={this.handleChange}
                                modules={modules}
                                formats={global.formats}
                                className="custom_editor_style"
                              />
                              {this.state.showModal && (
                                <LinkModal
                                  onClose={this.handleModalClose}
                                  onSubmit={this.handleModalSubmit}
                                  onRemove={this.handleModalRemove}
                                  url={this.state.url}
                                  text={this.state.text}
                                  isEditing={this.state.isEditing}
                                  showModal={this.state.showModal}
                                />
                              )}
                              </>
                              : (<div className="preview" dangerouslySetInnerHTML={{ __html: this.state.note }} />) }
                            </div>
                            {/* Images Section Start */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-20 pd-r-70">
                              <div className="row m-0">
                                <FileWidget
                                  fieldName="file_name"
                                  fieldTitle=""
                                  fieldLabel=""
                                  checkValidation="true"
                                  allowedType="jpeg,jpg,png,JPEG,JPG,PNG,GIF,gif"
                                  maxFileSize={5}
                                  maxFileSizeType="mb"
                                  maxFileAllowed={10}
                                  uploadOn="s3"
                                  refTableId="1"
                                  displayFileName=""
                                  documentTypeSlug=""
                                  mediaType={1}
                                  sortOrder=""
                                  statusId=""
                                  helpText="To download sample file."
                                  sampleFileUrl="/sample.jpg"
                                  drag="false"
                                  dragText=""
                                  inputClassName="form-control"
                                  labelClassName="form-label"
                                  formID="mro_form"
                                  uploadType="multiple"
                                  resizeOptions="width:200,height:200,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:300,height:300,maintainAspectRatio:false,resizeDestinationFolder:thumb22"
                                  destinationFolder="mros"
                                  uploadedFiles={this.state.profile_image2}
                                  deletedFiles={this.state.deletedFileList}
                                  acceptOnly="image/*"
                                  displayPreviewStyle="vertical"
                                  newRelatedFilesDescChange={
                                    this.newRelatedFilesDescChange
                                  }
                                  currentFile={this.state.mroImages.length}
                                />
                                {/* Image Preview */}
                                {this.state.mroImages
                                  ? this.state.mroImages.map((record, i) => {
                                      return (
                                        <div key={i}>
                                          <div className="img_close">
                                            <a
                                              href={
                                                record.direct_path &&
                                                record.direct_path
                                                  ? record.direct_path
                                                  : ""
                                              }
                                              rel="noreferrer"
                                              target="_blank"
                                            >

                                              {/* <img
                                                src={
                                                  record.direct_path
                                                    ? record.direct_path
                                                    : record.uri.uri
                                                }
                                                className="brand_list_image"
                                                alt={record.slug}
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    placeholderImage;
                                                }}
                                              /> */}
                                              <div className="pop-up-image-megnify">
                                                <ReactImageMagnify
                                                  {...{
                                                    smallImage: {
                                                      alt: record.slug,
                                                      isFluidWidth: true,
                                                      src: record.direct_path
                                                        ? record.direct_path
                                                        : record.uri.uri,
                                                      onError: ({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = placeholderImage;
                                                      },
                                                    },
                                                    largeImage: {
                                                      src: record.direct_path
                                                        ? record.direct_path
                                                        : record.uri.uri,
                                                      width: 200,
                                                      height: 200,
                                                    },
                                                    enlargedImagePosition: 'beside',
                                                    enlargedImageContainerDimensions: {
                                                      width: '300%',
                                                      height: '300%',
                                                    },
                                                    className: 'custom-image-magnify',
                                                  }}
                                                />
                                              </div>
                                            </a>
                                            {global.userPermissions.checkPermission(
                                              "mros-delete"
                                            ) && (
                                              <>
                                                <button
                                                  type="button"
                                                  className="btn btn-danger btn_remove"
                                                  onClick={() =>
                                                    this.toggleModal(
                                                      record.slug
                                                    )
                                                  }
                                                >
                                                  <span className="fa fa-times"></span>
                                                </button>
                                              </>
                                            )}
                                          </div>

                                          
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                              {/* Image Preview end*/}
                            </div>
                            {/* Property */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Property
                              </label>
                              {(this.state.slug === undefined && Number(localStorage.getItem('activePropertyCount')) !== 1) ? (
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  value={this.state.selectedPropertyList}
                                  options={this.state.propertyList}
                                  onChange={(value) =>
                                    this.handleChangeProperty(value)
                                  }
                                  defaultValue={this.state.selectedPropertyList}
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                              ) : (
                                <input
                                  name="job_title"
                                  className="form-control"
                                  autoComplete="off"
                                  value={this.state.propertyName}
                                  onChange={this.changeHandler}
                                  readOnly
                                />
                              )}
                              {this.state.errors.property_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.property_slug}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Property",
                                  this.state.property_slug,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Zone Type */}
                            {/* <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Zone
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedZoneTypeList}
                                options={this.state.zoneTypeList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeZoneType(value)
                                }
                                defaultValue={this.state.selectedZoneTypeList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                              {this.state.errors.zone_type ? (
                                <div className="text-danger">
                                  {this.state.errors.zone_type}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Zone",
                                  this.state.zone_type,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div> */}
                            {/* Location */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Location
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selecteLocationList}
                                options={this.state.locationList}
                                onChange={(value) =>
                                  this.handleChangeLocation(value)
                                }
                                defaultValue={this.state.selecteLocationList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                              {this.state.errors.location_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.location_slug}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Location",
                                  this.state.location_slug,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Priority */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Priority (Optional)
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedPriorityType}
                                options={this.state.priorityType}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangePriority(value)
                                }
                                defaultValue={this.state.selectedPriorityType}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                              {/* {this.state.errors.priority_type ? (
                                <div className="text-danger">
                                  {this.state.errors.priority_type}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Priority",
                                  this.state.priority_type,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )} */}
                            </div>
                            {/* Status */}
                            {this.state.slug !== undefined ? (
                              <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                                <label className="form-label text-left mg-b-5">
                                  Status
                                </label>
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  value={this.state.selectedStatusList}
                                  options={this.state.statusList}
                                  isSearchable={true}
                                  onChange={(value) =>
                                    this.handleChangeStatus(value)
                                  }
                                  defaultValue={this.state.selectedStatusList}
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                                {this.state.errors.status_id ? (
                                  <div className="text-danger">
                                    {this.state.errors.status_id}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "Status",
                                    this.state.status_id,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* Technician */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                               {this.state.slug === undefined ? "Assign to": "Assigned to"}
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedUserList}
                                options={this.state.userList}
                                onChange={(value) =>
                                  this.handleChangeUserList(value)
                                }
                                defaultValue={this.state.selectedUserList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                              {this.state.errors.user_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.user_slug}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Assign to",
                                  this.state.user_slug,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Due Date */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Due Date
                              </label>
                              <DatePicker
                                className="form-control max_width_100"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="due_date"
                                placeholderText="mm-dd-yy"
                                dateFormat="dd MMM yy"
                                selected={this.state.due_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "due_date",
                                      value: data,
                                    },
                                  })
                                }
                                minDate={Moment().toDate()}
                              />
                              {this.state.errors.due_date ? (
                                <div className="text-danger">
                                  {this.state.errors.due_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Due Date",
                                  this.state.due_date,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-80 pd-r-80">
                            <div className="col-md-6 btnNavigate d-flex align-items-center flex-wrap-bothBtn p-0">
                              {global.userPermissions.checkPermission(
                                "mros-delete"
                              ) &&
                                (this.state.slug !== undefined ? (
                                  <>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.opendeleteMroModal(
                                          this.state.slug
                                        );
                                      }}
                                      className="detlet-text mg-r-20"
                                    >
                                      Delete MRO
                                    </button>
                                  </>
                                ) : (
                                  ""
                                ))}
                            </div>
                            <div className="col-md-6 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                              <div className="d-flex align-items-center">
                                <Link to="/mros">
                                  <button
                                    className="gf-btn-lg-outline mg-r-7"
                                    type="button"
                                    disabled={
                                      this.state.isSubmit ? true : false
                                    }
                                  >
                                    Cancel
                                  </button>
                                </Link>
                                {this.state.slug == undefined &&
                                  global.userPermissions.checkPermission(
                                    "mros-add"
                                  ) && (
                                    <button
                                      className="gf-btn-lg-stroke mg-l-2"
                                      type="button"
                                      onClick={this.saveOrUpdateMro}
                                      disabled={
                                        this.state.isSubmit ? true : false
                                      }
                                    >
                                      {this.state.isSubmit
                                        ? global.loader
                                        : "Add"}
                                    </button>
                                  )}
                                {this.state.slug !== undefined &&
                                  global.userPermissions.checkPermission(
                                    "mros-update"
                                  ) && (
                                    <button
                                      className="gf-btn-lg-stroke mg-l-2"
                                      type="button"
                                      onClick={this.saveOrUpdateMro}
                                      disabled={
                                        this.state.isSubmit ? true : false
                                      }
                                    >
                                      {this.state.isSubmit
                                        ? global.loader
                                        : "Update"}
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            {/* Nav bar end */}
          </div>
        </main>
        {this.state.showModalImage && (
          <DeleteImageModal
            showModalImage={this.state.showModalImage}
            slug={this.state.imageSlug}
            onRemove={() => this.deleteMroImage(this.state.imageSlug)}
            onClose={this.onCloseImagePop}
          />
        )}
        <Modal show={this.state.deleteMroModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete MRO?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this MRO? This process cannot be
                undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteMroModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteMro(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.alertModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Alert Message?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Please select property first.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeAlertModal()}
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default MroForm;
