/*
 * Program: User.jsx
 * Application: User
 * Option: to List out of User Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-31
 */
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import UserService from "../../services/UserService";
import { Link } from "react-router-dom";
import { Button, Row, Col, Modal, Form } from "react-bootstrap";
import back from "./../../assets/images/back.svg";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import CustomerService from "../../services/CustomerService";
import exportIcon from "./../../assets/images/export-icon.svg";
import PropertySelection from "../dashboard/PropertySelection";
import TrackeService from "../../services/TrackeService";

export class User extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      selectRoleList: [],
      hideCustomerList: 0,
      // hidePropertyList: 0,
      customer_slug: "",
      customerList: [],
      selectedCustomer: [],
      selectedCustomerList: [],
      property_slug: [],
      propertyList: [],
      selectedProperties: [],
      selectedPropertyList: [],
      selectedRoleType: "",
      selectedRoleList: [],
      isFilterTrue: false,
      perPage: 0,
      exportType: "pdf",
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      config: {
        sort: {
          column: "first_name",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        // show_filter: false,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by first name, last name, email id, job title",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className={record.status_id === 1 && record.is_blocked === 1 ? "status-inactive" : "status-active"}>
                {record.status_id_disp ? record.status_id_disp : ""} {record.is_blocked === 1 ? '(Blocked)' : ""}
              </b>
            ) : (
              <b className="status-inactive">
                {record.status_id_disp ? record.status_id_disp : ""} {record.is_blocked === 1 ? '(Blocked)' : ""}
              </b>
            );
          },
        },
        {
          key: "first_name",
          text: "First Name",
          className: "first_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email ID",
          className: "email hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "phone",
          text: "Contact Number",
          className: "phone hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.phone
              ? record.phone
              : "";
          },
        },
        {
          key: "job_title",
          text: "Job Title",
          className: "role_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.job_title ? record.job_title : "";
          },
        },
        {
          key: "property_name",
          text: "Property",
          className: "email hand-cursor",
          sortable: false,
          cell: (record) => {
            // Split the properties list into an array
            let propertyListArray = record.propertiesLists.split(",");

            // Get the total count of properties
            let propertyCount = propertyListArray.length;

            // Variable to store the property list
            var propertyList;

            // Check if there are more than 2 properties
            if (propertyCount > 1) {
              // Concatenate the first two properties with a "More" link
              propertyList = propertyListArray.slice(0, 1).concat(" ",
                 <strong>+<Link to={`${record.slug}`}>{propertyCount - 1} More</Link></strong>
              );
            } else {
              // If there are 2 or fewer properties, only include them in the list
              propertyList = propertyListArray;
            }

            if(record.role_type_name === "System Admin")
            {
              return "All Properties";
            }

            // Return the constructed property list
            return propertyList;

          },
        },
        {
          key: "role_id",
          text: "Role",
          className: "role_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.role_type_name;
          },
        },
      ],
      isFirstLoad: true,
      lastQueryString: "",
      singleScreen: false,
      fistLoad: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.insPdfGenerator = this.insPdfGenerator.bind(this);
    this.getPropertySlug = this.getPropertySlug.bind(this);
    this.openExportModal = this.openExportModal.bind(this);
  }

  async insPdfGenerator(queryString = "", type = "") {
    var isPdf = 1;
    if (type == "csv") {
      isPdf = 2;
    }
      this.setState({ loadingexport: true });
      var list = [];
      let filterData = JSON.parse(localStorage.getItem("userFilterState")); 
      if (filterData) {
        queryString =
        "property_slug=" + (filterData?.property_slug || "") +
        "&role_id=" + (filterData?.role_id || "") +
        "&customer_slug=" + (filterData?.customer_slug || "") +
        "&status_id_filter=" + (filterData?.status || "") +
        "&brand_name=" + (filterData?.selectedCustomerList?.label || "") +
        "&property_name=" + (filterData?.selectedPropertyList?.label || "") +
        "&role_name=" + (filterData?.selectedRoleList?.label || "") +
        "&sort_column=" + (this.state?.sort_column || "") +
        "&sort_order=" + (this.state?.sort_order || "") +
        "&is_pdf=" + isPdf + "&is_filter=1";
    
      } else {
        queryString =
         "sort_column=" +
          (this.state.sort_column ? this.state.sort_column : "") +
          "&sort_order=" +
          (this.state.sort_order ? this.state.sort_order : "") +
          "&is_pdf=" +
          isPdf;
      }
      if (isPdf == 2) {
            queryString = queryString + "&request_type=6";
            let response = await TrackeService.trackerCsvExport(queryString);
            if (response.status === 200) {
              const blob = new Blob([response.data], { type: "text/csv" });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "users_export.csv");
              document.body.appendChild(link);
              link.click();
              link.remove();
            } else {
              console.error("Error exporting CSV:", response.message);
            }
            this.setState({
              loadingexport: false,
              list: list,
            });
          } else {
            let res = await UserService.getUserList(queryString);
            if (global.successStatus.includes(res.status)) {
              list = res.data.report["0"] ? res.data.report["0"] : [];
            }
            this.setState({
              loadingexport: false,
              list: list,
            });
            window.open(this.state.list.filePath, "_blank");
          }
    }

  componentDidMount() {
    const savedFilterState = JSON.parse(localStorage.getItem('userFilterState'));
    const savedFormattedData = JSON.parse(localStorage.getItem('formattedDataUser'));
    if (savedFilterState) {
      this.setState({
        role_id: savedFilterState.role_id,
        customer_slug: savedFilterState.customer_slug,
        property_slug: savedFilterState.property_slug,
        status: savedFilterState.status,
        selectedRoleList: savedFilterState.selectedRoleList,
        selectedCustomerList: savedFilterState.selectedCustomerList,
        selectedPropertyList: savedFilterState.selectedPropertyList,
        selectedStatus: savedFilterState.selectedStatus,
        isFilterTrue: true,
        module_search: savedFilterState.module_search,
        is_filter: savedFilterState.is_filter,
        formattedData: savedFormattedData || [],
      }, () => {
        let queryString =
          "role_id=" + (this.state.role_id ? this.state.role_id : '') +
          "&is_filter=1&" +
          "customer_slug=" + (this.state.customer_slug ? this.state.customer_slug : '') +
          "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
          "&status=" + (this.state.status ? this.state.status : '');
          
        this.getData(queryString);
        this.getPropertyList();
        this.setState({ tableQueryString: "", customQueryString: queryString, savedFilterState: savedFilterState });
      });
    } else {
      this.getData();
      this.getPropertyList();
    }
  }
  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      var formattedDataProperty = JSON.parse(localStorage.getItem("formattedDataUser"));
      this.setState({ formattedData: formattedDataProperty });
    }, 1000);
    setTimeout(() => {
      this.getData();
      this.getPropertyList();
    }, 200);
    this.setState({
      unit_number: "",
      module_search: [],
      is_search: 0,
    });
  }
  async getData(queryString = "") {
    this.setState({ showSpinner: true });
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    let propertySlug = "";
    let propertyName = "";
    let localSelectedProperty = "";

    if (localStorage.getItem("activePropertyCount") == 1) {
      localSelectedProperty = {
        label: localStorage.getItem("singlePropertyName"),
        value: localStorage.getItem("singlePorpertySlug"),
        };
    } else {
      if (this.state.singleScreen === true) {
        localSelectedProperty = this.state.selectedPropertyList;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
      } else {
        if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
          localStorage.getItem("outSidePorpertySlug") !== "") {
        propertySlug = localStorage.getItem("outSidePorpertySlug");
        propertyName = localStorage.getItem("selectedPropertyName");
        if (this.state.fistLoad === false) {
          queryString = queryString ? `${queryString}&property_slug=${propertySlug}` : `property_slug=${propertySlug}`;
          this.setState({fistLoad:true});
        } else {
          queryString += `&property_slug=${propertySlug}`;
        }
        var formattedData = JSON.parse(localStorage.getItem("formattedDataUser"));
        if(formattedData && formattedData !== null && formattedData !== undefined) {
          // Define the new property value
          var newPropertyValue = "Property: " + propertyName;

          // Loop through the array and replace the Property value
          formattedData = formattedData.map(item => {
              if (item.startsWith("Property:")) {
                  return newPropertyValue; // Replace Property value
              }
              return item; // Keep other values unchanged
          });

          // Store the updated array back in localStorage
          localStorage.setItem("formattedDataUser", JSON.stringify(formattedData));
        }
         localSelectedProperty = {
          label: propertyName,
          value: propertySlug,
        };
        } else {
          localSelectedProperty = [];
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
          
      }
      }
     
    }
    let list = [];
    var totalRecords = 0;
    let res = await UserService.getUserList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total;
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      records: [],
    });
    this.setState({
      loading: false,
      isFilterTrue: false,
      total_record: totalRecords,
      records: list,
      selectedPropertyList:localSelectedProperty,
      showSpinner: false,
    });
  }

  getRoleList() {
    const roleList = global.selectRoleList;
    let roles = [];
    if (roleList != null && roleList.length > 0) {
      roleList.forEach((item, i) => {
       // if (item.value > global.roleTypeId) {
          roles.push({
            value: item.value,
            label: item.name,
          });
       // }
      });
    }
    this.setState({ selectRoleList: roles });
  }

  // Get Customer List
  async getCustomerList(queryString = "") {
    var customerList = [];
    var customerListData = [];
    let res = await CustomerService.getCustomerList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      customerList = res.data ? res.data : [];
    }
    this.setState({ customerList: customerList });
    customerList.forEach((item) => {
      customerListData.push({
        label: item.customer_name,
        value: item.slug,
      });
    });
    this.setState({ customerList: customerListData });
  }

  async getPropertyList(customerSlug = "", queryString = "",) {
    this.setState({ loading: true });
    var propertyList = [];
    var propertyListData = [];
    var res = ""
    if (customerSlug != "") {
      this.setState({ selectedPropertyList: [] });
      res = await PropertyService.getPropertyByCustomer(
        "",
        (queryString = "customer_slug=" + customerSlug)
      );
    } else {
      res = await PropertyService.getPropertyList(
        "is_dropdown=1" + (queryString ? "&" + queryString : "")
      );
    }

    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });

    this.setState({ propertyList: propertyListData });
    this.setState({ loading: false });
  }

  async getPropertyData(customerSlug = "", queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    var propertyListCount = 0;
    let res = await PropertyService.getPropertyByCustomer(
      "",
      (queryString = "customer_slug=" + customerSlug)
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListCount = propertyList.length;
    }
    this.setState({ propertyListTotal: propertyListCount });
    if (this.state.propertyListTotal > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          value: item.slug,
          label: item.property_name,
        });
      });
    }
    this.setState({ propertyList: propertyListData });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };
  
  customFilter() {
    let queryString = 
      "role_id=" + (this.state.role_id ? this.state.role_id : '') +
      "&is_filter=1&" +
      "customer_slug=" + (this.state.customer_slug ? this.state.customer_slug : '') +
      "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
      "&page_size=" + (this.state.perPage ? this.state.perPage : '') +
      "&status=" + (this.state.status ? this.state.status : '');
      if (this.state.perPage == this.state.total_record) {
        queryString += `&all_records=all`;
      }
    if (queryString !== "") {
        this.setState({ lastQueryString: queryString });
        this.searchToggle();
        this.setState({ is_filter: 1 });
    }

    const dataFiltered = JSON.stringify({
      role_id: this.state.role_id,
      customer_slug: this.state.customer_slug,
      property_slug: this.state.property_slug,
      status: this.state.status,
      selectedRoleList: this.state.selectedRoleList,
      selectedCustomerList: this.state.selectedCustomerList,
      selectedPropertyList: this.state.selectedPropertyList,
      selectedStatus: this.state.selectedStatus,
      is_filter: 1
    })
    // Saving filter state to localStorage
    localStorage.setItem('userFilterState', dataFiltered);

    const result1 = JSON.parse(dataFiltered);
    const formattedData = [];
    if(result1?.selectedRoleList?.label) {
      formattedData.push(`Role: ${result1?.selectedRoleList?.label}`);
    }
    if(result1?.selectedCustomerList?.label) {
      formattedData.push(`Brand: ${result1?.selectedCustomerList?.label}`);
    }
    if(result1?.selectedPropertyList?.label) {
      formattedData.push(`Property: ${result1?.selectedPropertyList?.label}`);
    } else {
      if (
        (this.state.singleScreenProperty && this.state.singleScreenProperty !== "All Properties") || 
        (localStorage.getItem("selectedPropertyName") && localStorage.getItem("selectedPropertyName") !== "All Properties")
      ) {
        const propertyName = this.state.singleScreenProperty 
          ? this.state.singleScreenProperty 
          : localStorage.getItem("selectedPropertyName");
        formattedData.push(`Property: ${propertyName}`);
      }
    }
    if(result1?.selectedStatus?.label) {
      formattedData.push(`Status: ${result1?.selectedStatus?.label}`);
    }
    this.setState({ formattedData });
    localStorage.setItem('formattedDataUser', JSON.stringify(formattedData));
    // Fetch data with the new query string
    this.getData(queryString);

    if (this.state.showFilter === 1) {
        document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    this.setState({ is_filter: 0 });
    this.setState({
      selectedRoleType: "",
      selectedPropertyList: [],
      selectedCustomerList: [],
      customerList: [],
      role_id: "",
      customer_slug: "",
      property_slug: "",
      selectedRoleList: [],
      selectedStatusList: [],
      lastQueryString: "",
      singleScreenProperty: "",
      singleScreen: false,
      status:"",
      selectedStatus : "",
      formattedData: [],
    });
    localStorage.removeItem('userFilterState');
    localStorage.removeItem('formattedDataUser');
    this.getData("resetCall");
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ showFilter: 1 });
      this.getRoleList();
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeRole(value) {
    if (value != null) {
      /* use to hide show the dropdown list start */
      this.setState({
        selectedPropertyList: [],
        selectedCustomerList: [],
        customerList: [],
        // propertyList: [],
        selectedRoleType: value.value,
      });
      if (value.value === "2") {
        this.setState({ hideCustomerList: 1 });
        // this.setState({ hidePropertyList: 0 });
        this.getCustomerList();
      } else if (
        value.value === "3" ||
        value.value === "5" ||
        value.value === "6"
      ) {
        this.setState({ hideCustomerList: 1 });
        // this.setState({ hidePropertyList: 1 });
        this.getCustomerList();
      } else if (value.value === "4") {
        this.setState({ hideCustomerList: 0 });
        this.setState({ hidePropertyList: 1 });
        this.getPropertyData("&property_user=1");
      } else {
        this.setState({ hideCustomerList: 0 });
        // this.setState({ hidePropertyList: 0 });
      }
      /* use to hide show the dropdown list end*/
      this.setState({
       selectedRoleList: value,
        role_id: value.value,
        role_name: value.label,
      });
    }
  }
  // Customer Change Handler
  handleChangeCustomerList(value) {
    this.setState({
      selectedCustomerList: value,
      selectedPropertyList: [],
      customer_slug: value.value,
      customer_name1: value.label,
    });
    if (
      this.state.selectedRoleType == 3 ||
      this.state.selectedRoleType == 5 ||
      this.state.selectedRoleType == 6
    ) {
      this.getPropertyData(value.value);
    }
  }

  // Properties Change Handler
  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedPropertyList: value,
      property_slug: value.value,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
      propertyName1: value.label
    });
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal() {
    this.setState({ showExportModal: false });
  }

  changeExport = (evt) => {
    let value = evt.target.value;
    this.setState((prevState) => ({ ...prevState, exportType: value }));
  };

  exportData() {
    let exportType = this.state.exportType;
    if (exportType === "pdf") {
      this.insPdfGenerator();
    } else if (exportType === "csv") {
      this.insPdfGenerator("", "csv");
    }
  }

  tableChangeHandler = (data) => {
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            // newKey = "per_page";
            this.setState({ perPage: data[key]});
          }
          params.append(newKey, data[key]);
        }
      });
      if (data.page_size == this.state.total_record) {
        params.append("all_records", "all");
      }
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
      const updatedParams = new URLSearchParams(this.state.lastQueryString);
    
    // Check if 'page_size' exists in the parameters
    if (updatedParams.has('page_size')) {
        updatedParams.delete('page_size');
        // Update state with modified parameters
        this.setState({ lastQueryString: updatedParams.toString() });
    }
      // Fetch data with the updated query string
      this.getData(`${queryString}${updatedParams.toString()}`).finally(() => {
          // Hide the spinner after data is fetched
          this.setState({ showSpinner: false });
      });
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("user/update/" + data.slug);
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }
  handleChangeStatus(value){
    let status = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status.push(item.value);
      });
    }
    this.setState({
      selectedStatus: value,
      status: value.value,
      status_value: value.label,
    });
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main>
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
              <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
              </div>
            </div>
          )}
          {this.state.showSpinner}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Users</h3>
                        <div className="btnNavigate d-flex justify-content-end">
                         {/* <Button
                            onClick={this.openExportModal}
                            className="gf-primary-btn-small-one mg-l-7 loader_btn align-items-center export_pdf_btn"
                          >
                            Export
                          </Button> */}
                        {global.userPermissions.checkPermission("user-add") && (
                          <Link to={"/user/add"}>
                            <button className="gf-primary-btn-small-one mg-l-7 btn btn-primary ">
                              Add Users
                            </button>
                          </Link>
                           )}
                          <button
                            onClick={this.filterToggle}
                            className="gf-primary-btn-small-one mg-l-7 btn btn-primary"
                          >
                            {" "}
                            {this.state.showFilter === 1 ? "Filter" : "Filter"}
                          </button>
                        </div>
                      </div>
                      <span>All Users</span>
                    </h3>
                  </div>
                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back">
                      <div className="order_form">
                        <div className="d-flex justify-content-between align-items-center w-100 ">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} alt="" />
                            Back
                          </h3>
                        </div>
                        <h3>Filter</h3>
                        <div className="filter-box-scroll">
                          <Row className="align-items-start justify-content-between m-0 p-0">
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0 pb-0"
                            >
                              <label className="form-label text-left mg-b-5 mt-0 ">
                                Role{" "}
                              </label>
                              <Select
                                className="multiselect new_drop select_width"
                                menuPlacement="auto"
                                value={this.state.selectedRoleList}
                                options={this.state.selectRoleList}
                                onChange={(value) =>
                                  this.handleChangeRole(value)
                                }
                                defaultValue={this.state.selectedRoleList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            {this.state.hideCustomerList === 1 ? (
                              <>
                                {/* Customer Admn */}
                                <Col
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="mb-md-0 mb-3 pl-0 pb-0"
                                >
                                  <label className="form-label text-left mg-b-5">
                                    Brand Name{" "}
                                  </label>
                                  <Select
                                    className="multiselect new_drop"
                                    menuPlacement="auto"
                                    value={this.state.selectedCustomerList}
                                    options={this.state.customerList}
                                    onChange={(value) =>
                                      this.handleChangeCustomerList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedCustomerList
                                    }
                                    autoComplete="off"
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    styles={customStyles}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary75: "#808080",
                                        primary50: "#808080",
                                        primary25: "#808080",
                                      },
                                    })}
                                  />
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                            {/* Property List */}
                            {/* {this.state.hidePropertyList === 1 ? ( */}
                            <>
                              <Col
                                md={12}
                                lg={12}
                                xl={12}
                                className="mb-md-0 mb-3 pl-0 pb-0"
                              >
                                <label className="form-label text-left mg-b-5">
                                  Property{" "}
                                </label>
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  value={this.state.selectedPropertyList}
                                  options={this.state.propertyList}
                                  onChange={(value) =>
                                    this.handleChangePropertyList(value)
                                  }
                                  defaultValue={this.state.selectedPropertyList}
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                              </Col>
                            </>
                            <>
                              <Col
                                md={12}
                                lg={12}
                                xl={12}
                                className="mb-md-0 mb-3 pl-0 pb-0"
                              >
                                <label className="form-label text-left mg-b-5">
                                  Status
                                </label>
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  value={this.state.selectedStatus}
                                  options={global.activeStatusWeb }
                                  onChange={(value) =>
                                    this.handleChangeStatus(value)
                                  }
                                  defaultValue={this.state.selectedStatus}
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                              </Col>
                            </>

                            <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                {this.state.isFilterTrue
                                  ? global.loader
                                  : "Search"}
                              </button>
                            </Row>
                          </Row>
                        </div>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="mg-tneg-4 brandList dashboardRightcard">
            <PropertySelection
              propertySlug={this.getPropertySlug.bind(this)}
              outSidePropertySlug={this.state.outSidePropertySlug}
            />
            {this.state.formattedData && this.state.formattedData.length > 0 ? (
                <>
                  {/* <div className="col-xxl-12 col-sm-12 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                    <div className="custm-filter">
                      <div> */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="checklist_table_heading">
                        {" "}
                        <span className="lspan">
                        {(this.state.formattedData && this.state.formattedData.length && this.state.formattedData.join(', ')) ? <> Search Applied On:&nbsp;{" "} </>: ''}
                        </span>
                        <span className="rspan">
                        {(this.state.formattedData && this.state.formattedData.length > 0) ? this.state.formattedData.join(', ') : ''}

                        {(this.state.formattedData && this.state.formattedData.length > 0) && (
                          <a
                            type="button"
                            onClick={this.resetData}
                            className="global-flt-clearBtn"
                          >
                            Clear
                          </a>
                        )}
                          </span>
                      {/* </div> */}
                    </div>
                    </div>
                  </div>
                </>
              ) : (
              ""
            )}
            {localStorage.getItem("outSidePorpertySlug") && (this.state.formattedData == undefined || this.state.formattedData.length == 0) ? (<>
                <div className="row">
                <div className="col-md-12">
                    <div className="checklist_table_heading"><span className="lspan">Search Applied On:&nbsp;</span><span className="rspan">{this.state.singleScreenProperty ? this.state.singleScreenProperty:localStorage.getItem("selectedPropertyName") }</span></div>
                </div>
              </div>
              </>) :""}
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
        <Modal show={this.state.showExportModal} centered>
          <Modal.Body className={`width_402 p-4 border_radius_14 `}>
            <span
              className="welcomeClose"
              onClick={() => this.closeExportModal()}
            >
              {global.closee}
            </span>

            <h3 className="text-center">Export</h3>

            <label>Select Export File Type : </label>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                <Form.Check
                  onChange={(e) => this.changeExport(e)}
                  name="alert_type"
                  value="pdf"
                  checked={this.state.exportType === "pdf" ? true : false}
                  type={"radio"}
                  id="pdf"
                  label={"PDF"}
                  className={"rdiobox mg-r-20"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                <Form.Check
                  onChange={(e) => this.changeExport(e)}
                  name="alert_type"
                  value="csv"
                  checked={this.state.exportType === "csv" ? true : false}
                  type={"radio"}
                  id="csv"
                  label={"CSV"}
                  className={"rdiobox mg-r-20"}
                />
              </div>
            </div>

            <div className="d-flex cnt_center row mt-3">
              <Button
                onClick={() => this.exportData()}
                className="btn btn btn-success btn-block mg-r-7 loader_btn align-items-center export_pdf_btn"
                disabled={this.state.records.length === 0 ? true : false}
              >
                <img src={exportIcon} alt=""  className="mb-0"/>
                {this.state.loadingexport ? global.loader : "Export"}
              </Button>
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeExportModal()}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default User;
