import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import placeholderImage from "./../../assets/images/image-placeholder.png";
import UserService from "../../services/UserService";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import Moment from "moment";
import AuditTemplateService from "../../services/AuditTemplateService";
import AuditService from "../../services/AuditService";
import imageCompression from "browser-image-compression";
import back from "./../../assets/images/back.svg";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "../CustomToolbar";
import LinkModal from "../LinkModal";
import Collapsible from "react-collapsible";
// import compressImage from '../common/compressImage';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color:
      state.isSelected && state.isFocused
        ? "white"
        : state.isSelected
        ? "black"
        : state.isFocused && "white",
    backgroundColor:
      state.isSelected && state.isFocused ? "gray" : state.isFocused && "gray",
    fontFamily: "Roboto-Regular",
    fontSize: "16px",
  }),
};
class AuditPerform extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.quillRef = React.createRef();
    this.state = {
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
      errors: {},
      input: {},
      isSubmit: false,
      loading: false,
      isLoading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      checklist_slug: this.props.match.params.checklist_slug,
      property_slug: this.props.match.params.property_slug,
      audit_slug: this.props.match.params.audit_slug,
      error: "",
      openCollapsibles: [],
      openIndex: false,
      steps: [
        {
          key: "firstStep",
          label: "Info",
          isDone: true,
          component: this.firstComponent,
        },
        {
          key: "secondStep",
          label: "Audit",
          isDone: false,
          component: this.secondComponent,
        },
        {
          key: "finalStep",
          label: "Review",
          isDone: false,
          component: this.thirdComponent,
        },
      ],
      activeStep: {
        key: "firstStep",
        label: "Info",
        isDone: true,
        component: this.firstComponent,
      },
      propertyList: [],
      zoneTypeList: [],
      locationName: "",
      is_open: 0,
      showBasicModal: false,
      isCreateMroChecked: false,
      priorityType: global.priorityType,
      status: global.auditStatusWeb,
      selectedPriorityType: [],
      mroCategory: [],
      selectedMroCategory: [],
      userList: [],
      selectedUserList: [],
      in_time: Moment(new Date()).toDate("dd-MM-YYYY | hh:mm A"),
      out_time: "",
      is_image: false,
      login_user: global.userData.full_name,
      note: undefined,
      isImage: false,
      arrAllImages: [],
      arrAllImages1: [],
      status_id: 1,
      backIndex: 0,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.handleChangeUserList = this.handleChangeUserList.bind(this);
    this.handleChangePriority = this.handleChangePriority.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.changeHandlerForNote = this.changeHandlerForNote.bind(this);
    this.imagePreview = this.imagePreview.bind(this);
    this.handleAnswerOptionClick = this.handleAnswerOptionClick.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.stepFirstSave = this.stepFirstSave.bind(this);
    this.stepSecondSave = this.stepSecondSave.bind(this);
    this.stepThreeSave = this.stepThreeSave.bind(this);
    this.auditDetail = this.auditDetail.bind(this);
    this.submitModalforNote = this.submitModalforNote.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
  }
  componentDidMount() {
    if (this.state.checklist_slug !== undefined) {
      this.checklistDetail(this.state.checklist_slug);
    }
    setTimeout(() => {
      if (this.quillRef.current) {
        const quill = this.quillRef.current.getEditor();
        if (quill) {
          quill.getModule("toolbar").addHandler("link", this.handleLink);
          // Add event listener for clicks on links
          quill.root.addEventListener("click", this.handleLinkClick);
        } else {
          console.error("Quill editor is not initialized");
        }
      } else {
        console.error("quillRef.current is null");
      }
      if (this.state.category) {
        this.setState({
          openCollapsibles: Array(this.state.category.length).fill(true), // All collapsed by default
        });
      }
    }, 100); // The delay can be adjusted if necessary
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.quillRef.current && !prevState.note && this.state.note) {
      const quill = this.quillRef.current.getEditor();
      if (quill) {
        quill.getModule("toolbar").addHandler("link", this.handleLink);
        // Add event listener for clicks on links
        quill.root.addEventListener("click", this.handleLinkClick);
      }
    }
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }
  closeModal() {
    this.setState({ showBasicModal: false });
  }

  firstComponent = () => {
    return (
      <div>
        <div className="row row-xs">
          {this.state.description ? (
            <div>
              <label className="form-label text-left mb-0">
                Template Description
              </label>
              <p className="text-left mb-2">
                {this.state.description ? this.state.description : ""}
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12 mg-b-10">
            <label className="form-label text-left mg-b-5">
              {localStorage.getItem("ChecklistName")}
            </label>
          </div>
          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
            <label className="form-label mg-b-5">Performed By</label>
            <Form.Control
              name="user_slug"
              className="form-control max_width_100"
              type="text"
              value={this.state.login_user}
              readOnly
            />
          </div>
          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
            <label className="form-label mg-b-5">Conducted On</label>
            <DatePicker
              className="form-control max_width_100"
              showMonthDropdown
              showYearDropdown
              // scrollableYearDropdown
              autoComplete="off"
              yearDropdownItemNumber={global.yearDropdownItemNumber}
              name="in_time"
              placeholderText="mm-dd-yyyy"
              dateFormat="dd MMM yyyy | hh:mm a"
              selected={this.state.in_time}
              onChange={(data) =>
                this.changeHandler({
                  target: {
                    type: "date",
                    name: "in_time",
                    value: data,
                  },
                })
              }
              minDate={Moment().toDate()}
            />
            {this.state.in_time == undefined ? (
              <>
                <div className="text-danger">{this.state.in_time_error}</div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };

  // Get Checklist data
  async checklistDetail() {
    var resData = [];
    let res = await AuditTemplateService.getAuditTemplateRowBySlug(
      this.state.checklist_slug
    );
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data.data : [];
      let newCategory = resData.category.map((category) => {
        category["slug"] = null;
        category["is_selected_category"] = 0;
        category["audit_cate_percentage"] = 0;
        category["audit_cate_task_pass"] = 0;
        category["audit_cate_task_total"] = 0;
        category.task_name.map((task) => {
          task["slug"] = null;
          task["is_open"] = 0;
          task["is_selected"] = 0;
          task["is_action_with_note"] = 0;
          task["note_with_action_slug"] = null;
          task["note_with_action_id"] = null;
          task["task_status"] = null;
          task["note"] = null;
          task["priority_title"] = null;
          task["priority_type"] = null;
          task["action_technician"] = null;
          task["user_slug"] = null;
          task["due_date"] = null;
          task["checked"] = false;
          task["images"] = [];
          return task;
        });
        return category;
      });
      resData.category = newCategory ? newCategory : "";
    }

    this.setState(resData);
    this.setState({
      category: resData.category,
      openCollapsibles: Array(resData.category?.length || 0).fill(true),
    });
  }
  /* ON PRESS the checkbox for open answer */
  handleCheckboxClick(e, index, indexes) {
    let CheckChecked = e.target.checked;
    let category = this.state.category;
    let currentCategory = this.state.category[index];
    let curentTask = currentCategory.task_name[indexes];
    if (CheckChecked === true) {
      currentCategory.is_selected_category = 1;
      curentTask.is_open = 1;
      curentTask.is_selected = 1;
    } else {
      currentCategory.is_selected_category = 0;
      curentTask.is_open = 0;
      curentTask.is_selected = 0;
      curentTask.task_status = null;
    }
    this.setState({ category: category });

    /******************** Calculation category wise **********************/
    this.getCategoryScore(index);
    this.localDataStorage(this.state.category);
  }
  /* On press the answer button */
  handleAnswerOptionClick(ev, index, indexes) {
    ev.preventDefault();
    let nameOfEvent = ev.target.value;
    let category = this.state.category;
    let currentCategory = this.state.category[index];
    let curentTask = currentCategory.task_name[indexes];
    if (nameOfEvent === "YES") {
      category[index].task_name[indexes].task_status = 1;
      currentCategory.is_selected_category = 1;
      curentTask.is_open = 1;
      curentTask.is_selected = 1;
      this.setState({ category: category });
      this.localDataStorage(this.state.category);
    }
    if (nameOfEvent === "NO") {
      category[index].task_name[indexes].task_status = 2;
      currentCategory.is_selected_category = 1;
      curentTask.is_open = 1;
      curentTask.is_selected = 1;
      this.setState({ category: category });
      this.localDataStorage(this.state.category);
    }
    if (nameOfEvent === "NA") {
      category[index].task_name[indexes].task_status = 3;
      currentCategory.is_selected_category = 1;
      curentTask.is_open = 1;
      curentTask.is_selected = 1;
      this.setState({ category: category });
      this.localDataStorage(this.state.category);
    }

    /* Note or action item  */
    if (nameOfEvent === "NOTE") {
      this.mroAndNoteDataSet(category, index, indexes);
      this.setState({
        selectedTaskId: category[index].task_name[indexes].audit_task_id,
      });
      this.setState({
        showBasicModal: true,
        index: index,
        indexes: indexes,
      });
    }
    /******************** Calculation category wise **********************/
    this.getCategoryScore(index);
  }
  /* Set mro and note data */
  mroAndNoteDataSet(category, index, indexes) {
    var selectedPriorityType = {
      label: category[index].task_name[indexes].priority_title ?? null,
      value: category[index].task_name[indexes].priority_type ?? null,
    };
    var selectedUserList = {
      label: category[index].task_name[indexes].action_technician ?? null,
      value: category[index].task_name[indexes].user_slug ?? null,
    };
    var newDate =
      category[index].task_name[indexes].due_date != undefined
        ? Moment(category[index].task_name[indexes].due_date).toDate(
            "dd-MM-YYYY | hh:mm A"
          )
        : "";
    this.setState({
      task_name: category[index].task_name[indexes].task_name,
      task_slug: category[index].task_name[indexes].slug,
      note: category[index].task_name[indexes].note,
      checked: category[index].task_name[indexes].checked,
      selectedPriorityType: selectedPriorityType,
      selectedUserList: selectedUserList,
      due_date: newDate,
      arrAllImages: category[index].task_name[indexes].images,
    });

    this.localDataStorage(this.state.category);
  }

  /* handle mro and node submission */
  submitModalforNote(e) {
    e.preventDefault();
    var category = this.state.category;
    var index = this.state.index;
    var indexes = this.state.indexes;
    let currentCategory = this.state.category[index];
    let curentTask = currentCategory.task_name[indexes];
    if (this.state.checked === false) {
      if (this.state.note != undefined) {
        category[index].task_name[indexes].is_action_with_note = 2;
        category[index].task_name[indexes].checked = false;
        currentCategory.is_selected_category = 1;
        curentTask.is_open = 1;
        curentTask.is_selected = 1;
        this.mroAndNoteDataSet(category, index, indexes);
        this.setState({
          showBasicModal: false,
          noteMessage: "",
        });
      } else {
        this.setState({
          noteMessage: "The note is required.",
          showBasicModal: true,
        });
      }
    }

    if (this.state.checked === true) {
      if (
        this.state.priority_type != undefined &&
        this.state.user_slug != undefined &&
        this.state.due_date != undefined
      ) {
        if (category[index].task_name[indexes].checked === true) {
          category[index].task_name[indexes].is_action_with_note = 1;
          category[index].task_name[indexes].checked = true;
          currentCategory.is_selected_category = 1;
          curentTask.is_open = 1;
          curentTask.is_selected = 1;
          this.mroAndNoteDataSet(category, index, indexes);
          this.setState({
            showBasicModal: false,
            priority_type_error: "",
            user_slug_error: "",
            due_date_error: "",
          });
        }
      } else {
        this.setState({
          priority_type_error: "The priority field is required.",
          user_slug_error: "The assign to field is required.",
          due_date_error: "The due date is required.",
          showBasicModal: true,
        });
      }
    }
  }

  /* Category wise calculation */
  getCategoryScore = (index) => {
    let category = this.state.category;
    var includedTotalTasks = [];

    /* calculate included task */
    category[index].is_selected_category = 1;
    category[index].audit_cate_percentage = 0.0;
    var taskList = category[index].task_name;
    for (var j in taskList) {
      if (category[index].task_name[j].is_exclude === 2) {
        includedTotalTasks = [
          category[index].task_name[j],
          ...includedTotalTasks,
        ];
      }
    }
    if (includedTotalTasks.length > 0) {
      var naCount = 0;
      var passedTask = [];
      for (var i in includedTotalTasks) {
        var taskLists = includedTotalTasks[i];
        if (taskLists.is_selected == 1 && taskLists.task_status == 1) {
          passedTask = [taskLists, ...passedTask];
        }
        if (taskLists.is_selected == 1 && taskLists.task_status == 3) {
          naCount++;
        }
      }
      const totalCounts = includedTotalTasks.length - naCount;

      var percentageWithTwoDecimalPlaces = 0.0;
      var percentage = (passedTask.length / totalCounts) * 100;
      if (percentage > 0) {
        percentageWithTwoDecimalPlaces = parseFloat(percentage).toFixed(2); //12.23
      }
      category[index].is_selected_category = 1;
      category[index].audit_cate_percentage = percentageWithTwoDecimalPlaces;
      category[index].audit_cate_task_pass = passedTask.length;
      category[index].audit_cate_task_total = totalCounts;
    } else {
      category[index].is_selected_category = 1;
      category[index].audit_cate_percentage = 100;
    }
    this.setState({ category: category });
  };
  handleLink() {
    const quill = this.quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      this.setState({
        showModal: true,
        range,
        url: "",
        text: "",
        isEditing: false,
      });
    }
  }

  handleModalClose() {
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleModalSubmit(url, text) {
    const quill = this.quillRef.current.getEditor();
    const { range } = this.state;
    if (range && url && text) {
      quill.deleteText(range.index, range.length);
      quill.insertText(range.index, text, "link", url);
      quill.setSelection(range.index + text.length);
    }
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleLinkClick(event) {
    if (event.target.tagName === "A") {
      event.preventDefault();
      const quill = this.quillRef.current.getEditor();
      const range = quill.getSelection();
      const url = event.target.getAttribute("href");
      const text = event.target.innerText;

      // Prevent Quill's tooltip from showing
      quill.blur();

      this.setState({
        showModal: true,
        range: { index: range.index, length: text.length },
        url,
        text,
        isEditing: true,
      });
    }
  }

  handleModalRemove() {
    const quill = this.quillRef.current.getEditor();
    const textLength = quill.getLength();
    // let range = { index: 0, length: textLength };

    // Get the entire content and iterate over each character
    for (let i = 0; i < textLength; i++) {
      const [link] = quill.getFormat(i, 1).link || [];
      if (link) {
        // Remove the link format only, leaving the text intact
        quill.formatText(i, 1, "link", false);
      }
    }

    this.setState({ showModal: false, url: "", text: "", isEditing: false });
  }

  handleChange(value) {
    this.setState({ note: value }, () => {
      this.customValidate();
    });
  }
  /* Handle note with MRO notes */
  changeHandlerForNote = (value) => {
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].note = value;
    this.setState({ category: category, note: value });
  };
  /* Image preview */
  imagePreview = async (event) => {
    this.setState({ isLoading: true });
    const files = event.target.files;
    const { category, index, indexes, arrAllImages, backIndex, isImage } =
      this.state;
    let newImage = [];
    let newImage1 = [];
    let allImages = [];

    // Validation: Check total images
    const totalImages = arrAllImages.length + files.length;
    if (totalImages > 10) {
      this.setState({
        isLoading: false,
        error: "You can only upload a maximum of 10 images.",
      });
      event.target.value = ""; // Reset the file input
      return;
    }

    if (files.length >= 1) {
      for (let i = 0; i < files.length; i++) {
        const fileSize = files[i].size;
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        if (fileSize >= 2e6) {
          // Compress the image if it's larger than 2MB
          const compressedFile = await imageCompression(files[i], options);
          allImages.push({
            file: compressedFile,
            isActionwithNote:
              category[index].task_name[indexes].is_action_with_note,
            auditTaskId: category[index].task_name[indexes].audit_task_id,
            src: URL.createObjectURL(compressedFile),
          });
        } else {
          category[index].task_name[indexes].images = files[i];
          allImages.push({
            file: files[i],
            isActionwithNote:
              category[index].task_name[indexes].is_action_with_note,
            auditTaskId: category[index].task_name[indexes].audit_task_id,
            src: URL.createObjectURL(files[i]),
          });
        }
      }

      if (backIndex === 2) {
        const filteredArrAllImages = this.state.arrAllImages.filter(
          (img) => img.task_slug
        );

        const filteredNewImages1 = allImages.filter((img) => !img.task_slug);

        newImage = [...filteredArrAllImages, ...filteredNewImages1];

        const filteredArrAllImages1 = this.state.arrAllImages1.filter(
          (img) => !img.task_slug
        );

        const filteredNewImages = allImages.filter((img) => !img.task_slug);

        newImage1 = [...filteredArrAllImages1, ...filteredNewImages];
      } else {
        if (isImage === true) {
          newImage = [...this.state.arrAllImages, ...allImages];
          newImage1 = [...this.state.arrAllImages1, ...allImages];
        } else {
          newImage = allImages;
          newImage1 = allImages;
        }
      }
      /* Assign single task image into the cagegory */
      category[index].task_name[indexes].images = newImage;

      // Update state with new images
      await this.setState({
        category: category,
        isImage: true,
        arrAllImages: newImage,
        arrAllImages1: newImage1,
        isLoading: false,
        error: "",
        isBack: 1,
      });
      this.localDataStorage(this.state.category);
    } else {
      console.log("First select an image file");
      this.setState({ isLoading: false });
    }
  };

  /* Image close */
  deleteImage(i) {
    let arrAllImages = [...this.state.arrAllImages];
    arrAllImages.splice(i, 1);
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].images =
      arrAllImages;
    this.setState({ arrAllImages, category: category });
  }

  /* handle create mro checkbox */
  handleCheckboxChange(e) {
    let category = this.state.category;
    const checked = e.target.checked;
    /* check box checked for mro */
    if (checked === true) {
      category[this.state.index].task_name[
        this.state.indexes
      ].is_action_with_note = 1;
      category[this.state.index].task_name[this.state.indexes].checked = true;
      /* If action item created then call related APIs like mrocategory or technician */
      this.getUserList();
    } else {
      // category[this.state.index].task_name[this.state.indexes].is_action_with_note = 2;
      category[this.state.index].task_name[this.state.indexes].checked = false;
    }
    this.setState({
      category: category,
      checked: checked,
    });
  }

  /* Handle note with MRO notes */
  changeHandlerForNote = (value) => {
    // e.preventDefault();
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].note = value;
    this.setState({ category: category, note: value });
  };
  // Priority  Change Handler
  handleChangePriority(value) {
    let priority_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        priority_type.push(item.value);
      });
    }
    this.setState({
      selectedPriorityType: value,
      priority_type: value.value,
    });
    this.setState({ confirm_back: 1 });
    /* Set category data into category array */
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].priority_title =
      value.label;
    category[this.state.index].task_name[this.state.indexes].priority_type =
      value.value;
    this.setState({ category: category });
  }

  // Get Users List
  async getUserList(queryString = "") {
    this.setState({ loading: true });
    var userList = [];
    var userListData = [];
    queryString =
      "property_slug=" +
      (this.state.property_slug ? this.state.property_slug : "");
    let res = await UserService.getUserList(
      "is_dropdown=1&action_item=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }
    userList.forEach((item) => {
      userListData.push({
        label: item.full_name,
        value: item.slug,
      });
    });

    this.setState({ userList: userListData });
    this.setState({ loading: false });
  }
  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
    /* Set category data into category array */
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].action_technician =
      value.label;
    category[this.state.index].task_name[this.state.indexes].user_slug =
      value.value;
    this.setState({ category: category });
  }
  //Change handler for error and due date
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    if (event.target.name == "out_time") {
      this.setState({
        [event.target.name]: Moment(value).toDate("dd-MM-YYYY | hh:mm A"),
        input: input,
      });
    } else {
      this.setState({ [event.target.name]: value, input: input });
    }
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  /* Handle due date for mro */
  handleDueDate = (e) => {
    let category = this.state.category;
    category[this.state.index].task_name[this.state.indexes].due_date =
      e.target.value;
    this.setState({ category: category, due_date: e.target.value });
  };

  handleToggle = (index) => {
    this.setState((prevState) => {
      const openCollapsibles = [...prevState.openCollapsibles];
      openCollapsibles[index] = !openCollapsibles[index]; // Toggle state
      return { openCollapsibles };
    });
  };

  secondComponent = () => {
    const { openCollapsibles } = this.state;
    const { error } = this.state;
    const modules = {
      toolbar: {
        container: "#toolbar",
        handlers: {
          link: this.handleLink,
        },
      },
    };
    return (
      <div>
        <div className="row row-xs">
          <div className="col-md-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1">
            {this.state.description ? (
              <div>
                <label className="form-label text-left mb-0">
                  Template Description
                </label>
                <p className="text-left mb-2">
                  {this.state.description ? this.state.description : ""}
                </p>
              </div>
            ) : (
              ""
            )}
            <label className="form-label text-left mg-b-5">
              <strong className="checklist_title">Checklist:</strong>{" "}
              {this.state.audit_name}
            </label>
            {this.state.category !== undefined &&
              this.state.category.map((element, index) => (
                <div className="category_list_box" key={"cat_" + index}>
                  {/* Collapsible Component */}
                  <Collapsible
                    key={index}
                    trigger={
                      <div
                        className={`bedroom_title d-flex justify-content-between ${
                          openCollapsibles[index] ? "open" : ""
                        }`}
                        onClick={() => this.handleToggle(index)}
                      >
                        <span className="cate_per_title">
                          {element.category_name}
                        </span>
                        <span>{`${element.audit_cate_percentage}% (${element.audit_cate_task_pass}/${element.audit_cate_task_total})`}</span>
                        <span
                          className={`Collapsible-arrow ${
                            openCollapsibles[index] ? "open" : ""
                          }`}
                        >
                          <i
                            className={`fa ${
                              openCollapsibles[index]
                                ? "fa-angle-up"
                                : "fa-angle-down"
                            }`}
                          ></i>
                        </span>
                      </div>
                    }
                    open={openCollapsibles[index]} // Control collapsible state
                  >
                    {/* Task List */}
                    {element.task_name &&
                      element.task_name.map((el, indexes) => (
                        <div className="qes_ans_box" key={"task_" + indexes}>
                          <label className="check_cust">
                            <input
                              type="checkbox"
                              checked={
                                el.task_status === 1 ||
                                el.task_status === 2 ||
                                el.task_status === 3 ||
                                el.is_action_with_note === 1 ||
                                el.is_action_with_note === 2
                              }
                              name="task_status"
                            />
                            <span className="checkmark"></span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: this.modifyTaskName(el.task_name),
                              }}
                            />
                          </label>

                          <div className="checbox_cont_div d-block">
                            <div className="d-flex" key={"ans_" + indexes}>
                              {/* Yes */}
                              <button
                                className={
                                  "gf-btn-lg-outline mr-2 " +
                                  (el.task_status === 1
                                    ? "inspect_button_yes"
                                    : "inspect_button_new")
                                }
                                onClick={(ev) => {
                                  this.handleAnswerOptionClick(
                                    ev,
                                    index,
                                    indexes
                                  );
                                }}
                                value="YES"
                              >
                                Yes
                              </button>

                              {/* No */}
                              <button
                                className={
                                  "gf-btn-lg-outline mr-2 " +
                                  (el.task_status === 2
                                    ? "inspect_button_No"
                                    : "inspect_button_new")
                                }
                                onClick={(ev) => {
                                  this.handleAnswerOptionClick(
                                    ev,
                                    index,
                                    indexes
                                  );
                                }}
                                value="NO"
                              >
                                No
                              </button>

                              {/* NA */}
                              <button
                                className={
                                  "gf-btn-lg-outline mr-2 " +
                                  (el.task_status === 3
                                    ? "inspect_button_NA"
                                    : "inspect_button_new")
                                }
                                onClick={(ev) => {
                                  this.handleAnswerOptionClick(
                                    ev,
                                    index,
                                    indexes
                                  );
                                }}
                                value="NA"
                              >
                                NA
                              </button>

                              {/* Note */}
                              <button
                                className={
                                  "gf-btn-lg-outline mr-2 " +
                                  (el.is_action_with_note === 1
                                    ? "inspect_button_Note"
                                    : el.is_action_with_note === 2
                                    ? "inspect_button_Action"
                                    : "inspect_button_new")
                                }
                                onClick={(ev) => {
                                  this.handleAnswerOptionClick(
                                    ev,
                                    index,
                                    indexes
                                  );
                                }}
                                value="NOTE"
                              >
                                {el.is_action_with_note === 1
                                  ? "ACTION ITEM"
                                  : el.is_action_with_note === 2
                                  ? "NOTE"
                                  : "ADD NOTE"}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Collapsible>
                </div>
              ))}
          </div>
          <Modal show={this.state.showBasicModal} centered>
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span className="welcomeClose" onClick={() => this.closeModal()}>
                {global.closee}
              </span>

              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center mb-3"> Add Audit Note</h3>
                  <p className="note_task_name_box font-weight-bold model_first_p">
                    <label className="form-label">Property Name :</label>
                    {localStorage.getItem("propertyNameAuditPerform")}
                  </p>
                  <p className="note_task_name_box font-weight-bold model_first_p">
                    <label className="form-label">Task Name :</label>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.modifyTaskName(this.state.task_name),
                      }}
                    />
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <label className="form-label mg-b-5">Notes</label>
                  {/* <Form.Control
                    autoComplete="off"
                    name="note"
                    type="textarea"
                    as="textarea"
                    maxLength="500"
                    placeholder="Type here..."
                    onChange={this.changeHandlerForNote}
                    value={this.state.note}
                  /> */}
                  <CustomToolbar />
                  <ReactQuill
                    ref={this.quillRef}
                    theme="snow"
                    name="note"
                    value={this.state.note ?? ""}
                    onChange={this.changeHandlerForNote}
                    modules={modules}
                    formats={global.formats}
                    className="custom_editor_style"
                  />
                  {this.state.showModal && (
                    <LinkModal
                      onClose={this.handleModalClose}
                      onSubmit={this.handleModalSubmit}
                      onRemove={this.handleModalRemove}
                      url={this.state.url}
                      text={this.state.text}
                      isEditing={this.state.isEditing}
                      showModal={this.state.showModal}
                    />
                  )}
                  {this.state.note == undefined ? (
                    <>
                      <div className="text-danger">
                        {this.state.noteMessage != undefined
                          ? this.state.noteMessage
                          : ""}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <input note /> */}

              <div className="note_image_box">
                <div className="">
                  <input
                    // onChange={this.onImageChange}
                    onChange={this.imagePreview}
                    className="px-0"
                    type="file"
                    name="file_name"
                    tabIndex="2"
                    multiple
                  />
                  {/* Image Preview */}
                  <ul className="img_view_loop">
                    {(() => {
                      const dbImages =
                        this.state.taskImages?.[this.state.selectedTaskId] ||
                        []; // Database images
                      const uploadedImages = this.state.arrAllImages || []; // Newly uploaded images

                      // Merge images & remove duplicates (based on slug)
                      const uniqueImages = [
                        ...dbImages,
                        ...uploadedImages.filter(
                          (img) =>
                            !dbImages.some((dbImg) => dbImg.slug === img.slug)
                        ),
                      ];
                      console.log("uniqueImages", uniqueImages);
                      return uniqueImages.length > 0 ? (
                        uniqueImages.map((record, i) => (
                          <li key={i}>
                            <div className="img_close">
                              <a
                                href={record?.src || record?.uri || ""}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <img
                                  src={record?.src || record?.uri || ""}
                                  className="brand_list_image"
                                  alt="action image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = placeholderImage;
                                  }}
                                />
                              </a>
                              <button
                                type="button"
                                className="btn btn-danger btn_remove"
                                onClick={() => this.deleteImage(i)}
                              >
                                <span className="fa fa-times"></span>
                              </button>
                            </div>
                          </li>
                        ))
                      ) : ''
                    })()}
                  </ul>
                </div>
                {/* Image Preview end*/}
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}

              <div className="row">
                <div className="col-md-12">
                  <div className="qes_ans_box crete_mro_checkbox">
                    <label className="check_cust">
                      <input
                        type="checkbox"
                        defaultChecked={this.state.checked}
                        name="is_exclude"
                        onChange={(e) => this.handleCheckboxChange(e)}
                        value={this.state.is_action_with_note}
                      />
                      <span className="checkmark"></span>Create Action Item
                    </label>
                  </div>
                </div>
              </div>

              <div
                className={
                  "create_mro_field_section" +
                  (this.state.checked === true ? "d-block" : "")
                }
              >
                <div className="row">
                  <div className="col-md-12 mg-b-15">
                    <label className="form-label mg-b-5">Priority</label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedPriorityType}
                      options={this.state.priorityType}
                      isSearchable={true}
                      onChange={(value) => this.handleChangePriority(value)}
                      defaultValue={this.state.selectedPriorityType}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />

                    {this.state.priority_type == undefined ? (
                      <>
                        <div className="text-danger">
                          {this.state.priority_type_error}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 mg-b-15">
                    <label className="form-label mg-b-5"> Assign To</label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedUserList}
                      options={this.state.userList}
                      onChange={(value) => this.handleChangeUserList(value)}
                      defaultValue={this.state.selectedUserList}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                    {this.state.user_slug == undefined ? (
                      <>
                        <div className="text-danger">
                          {this.state.user_slug_error}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 mg-b-15">
                    <label className="form-label mg-b-5">Due Date</label>
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      // scrollableYearDropdown
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="due_date"
                      placeholderText="mm-dd-yyyy"
                      dateFormat="dd MMM yyyy"
                      selected={this.state.due_date}
                      onChange={(data) =>
                        this.handleDueDate({
                          target: {
                            type: "date",
                            name: "due_date",
                            value: data,
                          },
                        })
                      }
                      minDate={Moment().toDate()}
                    />
                    {this.state.due_date == undefined ? (
                      <>
                        <div className="text-danger">
                          {this.state.due_date_error}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex cnt_center row mt-3">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeModal()}
                  disabled={this.state.isLoading}
                >
                  Close
                </button>

                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block btn-thene-modal"
                  onClick={this.submitModalforNote}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? global.loader : "Submit"}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  };
  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }
  /* status change handle */
  handleChangeStatus(value) {
    let status = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status.push(item.value);
      });
    }
    this.setState({
      selectedStatus: value,
      status: value.value,
      status_id: value.value,
    });
    this.setState({ confirm_back: 1 });
    //  this.setState({ category: category, });
  }
  thirdComponent = () => {
    return (
      <div>
        <div className="row row-xs">
          <div className="col-md-12 d-none">
            <div className="az-content-label titleLable pl-0">Review</div>
          </div>

          <div className="col-md-12">
            {this.state.category != undefined
              ? this.state.category.map((element, index) => (
                  <span key={"rev" + index}>
                    {element.task_name
                      ? element.task_name.map((el, indexes) => (
                          <>
                            <span key={"revs" + indexes}>
                              {el.is_action_with_note === 1 ? (
                                <>
                                  <p className="titleLable pl-4 mb-1">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: this.modifyTaskName(
                                          el.task_name
                                        ),
                                      }}
                                    />
                                  </p>
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                          </>
                        ))
                      : ""}
                  </span>
                ))
              : ""}
          </div>
        </div>

        <div className="row row-xs">
          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
            <label className="form-label mg-b-5">Completed On</label>
            <DatePicker
              className="form-control max_width_100"
              showMonthDropdown
              showYearDropdown
              // scrollableYearDropdown
              autoComplete="off"
              yearDropdownItemNumber={global.yearDropdownItemNumber}
              name="out_time"
              placeholderText="mm-dd-yyyy"
              dateFormat="dd MMM yyyy | hh:mm a"
              selected={this.state.out_time}
              onChange={(data) =>
                this.changeHandler({
                  target: {
                    type: "date",
                    name: "out_time",
                    value: data,
                  },
                })
              }
              minDate={Moment().toDate()}
            />
          </div>
          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
            <label className="form-label mg-b-5">Audit Status</label>
            <Select
              name="status"
              className="multiselect new_drop"
              menuPlacement="auto"
              value={this.state.selectedStatus}
              options={this.state.status}
              isSearchable={true}
              onChange={(value) => this.handleChangeStatus(value)}
              defaultValue={this.state.status[0]}
              autoComplete="off"
              getOptionValue={(option) => `${option.label}`}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary75: "#808080",
                  primary50: "#808080",
                  primary25: "#808080",
                },
              })}
            />
            {this.state.errors.status ? (
              <div className="text-danger">{this.state.errors.status}</div>
            ) : (
              this.validator.message("Status", this.state.status, "required", {
                className: "text-danger",
              })
            )}
          </div>
        </div>
      </div>
    );
  };

  handleNext = async (e) => {
    const { steps, activeStep } = this.state;
    if (steps[steps.length - 1].key === activeStep.key) {
      this.stepThreeSave();
      return;
    }
    const index = steps.findIndex((x) => x.key === activeStep.key);
    /* Active SETP 1 */
    if (this.state.activeStep.key === "firstStep") {
      if (this.state.backIndex === 1) {
        this.setState({ steps, activeStep: steps[index + 1] });
      } else {
        if (
          this.state.property_slug != undefined &&
          this.state.checklist_slug != undefined &&
          this.state.in_time
        ) {
          if (localStorage.getItem("auditCategory") == undefined) {
            this.checklistDetail(this.state.checklist_slug);
          } else {
            this.setState({
              category: JSON.parse(localStorage.getItem("auditCategory")),
            });
          }

          // if (this.state.audit_slug != undefined) {

          //   let updatedCategory = await this.auditDetail(this.state.audit_slug);
          //   if (updatedCategory.length > 0) {
          //     if (localStorage.getItem("auditCategory") == undefined) {
          //       this.setState({ category: updatedCategory });
          //     } else {
          //       this.setState({category: JSON.parse(localStorage.getItem("auditCategory"))})
          //     }
          //   } else {
          //     if (localStorage.getItem("auditCategory") == undefined) {
          //       this.checklistDetail(this.state.checklist_slug);
          //     } else {
          //       this.setState({category: JSON.parse(localStorage.getItem("auditCategory"))})
          //     }
          //   }
          // }
          this.setState({ steps, activeStep: steps[index + 1] });
        }
      }
    }
    /* Active SETP 2 */
    if (this.state.activeStep.key === "secondStep") {
      /* Out time set */
      var outTime = Moment(new Date()).toDate("dd-MM-YYYY | hh:mm A");
      this.setState({ out_time: outTime });
      var resStepTwo = await this.stepSecondSave();
      if (resStepTwo === true) {
        this.getImageForUpload();
        /* isdone class update on press continiue button // result color change tab */
        const index = steps.findIndex((x) => x.key === activeStep.key);
        const updatedSteps = steps.map((x) => {
          if (x.key === activeStep.key) x.isDone = true;
          return x;
        });
        this.setState({ steps: updatedSteps, activeStep: steps[index + 1] });
      }
    }
    this.customValidate();
  };

  handleBack = async () => {
    const { steps, activeStep } = this.state;
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;
    steps[index].isDone = false;
    /* back press on step to so call the audit api again with updated data */
    if (index === 2) {
      let updatedCategory = await this.auditDetail(this.state.audit_slug);
      this.setState({ category: updatedCategory });
      this.localDataStorage(this.state.category);
    }
    this.setState({ steps, activeStep: steps[index - 1] });
    this.setState({ backIndex: index, isBack: 0 });
  };

  /* Get audit data */
  auditDetail = async (auditSlug) => {
    if (auditSlug !== undefined) {
      var resData = {};
      let res = await AuditService.getAuditRowBySlug(auditSlug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data.data ? res.data.data : {};
        let taskImages = {};
        let newCategory = resData.category.map((category) => {
          category["slug"] = category.slug ? category.slug : null;
          category["is_selected_category"] = category.audit_category_id ? 1 : 0;
          category["audit_cate_percentage"] =
            category.audit_cate_percentage ?? 0;
          category["audit_cate_task_pass"] = category.audit_cate_task_pass ?? 0;
          category["audit_cate_task_total"] =
            category.audit_cate_task_total ?? 0;
          category["task_name"] = category.tasks.map((task) => {
            task["slug"] = task.slug ?? null;
            task["is_open"] =
              task.is_action_with_note != 0 || task.task_status != 0 ? 1 : 0;
            task["is_selected"] =
              task.is_action_with_note != 0 || task.task_status != 0 ? 1 : 0;
            task["is_action_with_note"] = task.is_action_with_note ?? 0;
            task["note_with_action_slug"] =
              task.action && task.action.slug ? task.action.slug : null;
            task["note_with_action_id"] = task.note_with_action_id ?? null;
            task["task_status"] = task.task_status
              ? parseInt(task.task_status)
              : 0;
            task["note"] =
              task.action && task.action.note ? task.action.note : null;
            task["priority_title"] =
              task.action && task.action.priority_type_name
                ? task.action.priority_type_name
                : null;
            task["priority_type"] =
              task.action && task.action.priority_type
                ? task.action.priority_type
                : null;
            task["action_technician"] =
              task.action &&
              task.action.assignee &&
              task.action.assignee.full_name
                ? task.action.assignee.full_name
                : null;
            task["user_slug"] =
              task.action && task.action.assignee && task.action.assignee.slug
                ? task.action.assignee.slug
                : null;
            task["due_date"] =
              task.action && task.action.due_date ? task.action.due_date : null;
            // task['due_date'] =task.action && task.action.due_date ? Moment(task.action.due_date).toDate("dd-MM-YYYY | hh:mm A"): "";
            task["checked"] = task.is_action_with_note === 1 ? true : false;
            task["images"] =
              task.action && task.action.images ? task.action.images : [];
            if (task.audit_task_id) {
              taskImages[task.audit_task_id] = task.images;
            }
            return task;
          });
          category["tasks"] = null;
          return category;
        });
        this.setState({ taskImages });
        return newCategory;
      }
    }
  };

  onImageUpload = async (image) => {
    // if (image.length > 0 ) {
    try {
      const fileData = new FormData();
      fileData.append("file_name", image.file, image.file.name);
      fileData.append("is_action_with_note", image.isActionwithNote);
      fileData.append("audit_task_slug", image.task_slug);
      fileData.append("is_completed", true);
      fileData.append("refTableId", 3);
      let res = await AuditService.uploadInspFile(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          Images: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ loading: false });
      } else if (res.status === 413) {
        this.setState({
          loading: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } catch (error) {
      console.error("Error compressing or uploading image:", error);
      this.setState({
        loading: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: "An error occurred while processing the image.",
      });
    }
    // }
  };

  stepFirstSave = async () => {
    this.checklistDetail(this.state.checklist_slug);
  };

  stepSecondSave = async (e) => {
    this.setState({ loading: true, isSubmit: true });
    let inputData = {
      slug: this.state.audit_slug,
      property_slug: this.state.property_slug,
      out_time: null,
      category: this.state.category,
      is_image: this.state.is_image,
    };
    if (inputData != undefined) {
      let res = await AuditService.updateAudit(
        this.state.audit_slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        let responceData = res.data && res.data.data ? res.data.data : [];
        this.setState({
          inspectionData: responceData,
          // showAlertModal: true,
          // alertModalType: "success",
          // alertModalTitle: "Success",
          // alertModalMessage: res.message ? res.message : "Success",
        });

        this.setState({ loading: false, isSubmit: false });
        return true;
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
  };
  stepThreeSave = async (e) => {
    this.setState({ loading: true, isSubmit: true });
    let inputData = {
      slug: this.state.audit_slug,
      // property_slug: this.state.property_slug,
      // user_slug: global.userData.slug,
      // in_time: this.state.in_time,
      out_time: this.state.out_time,
      // category:this.state.category,
      status_id: this.state.status_id,
      // is_image: this.state.is_image,
    };
    if (inputData != undefined) {
      let res = await AuditService.updateAudit(
        this.state.audit_slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        let responceData = res.data && res.data.data ? res.data.data : [];
        this.setState({
          inspectionData: responceData,
        });
        localStorage.removeItem("auditCategory");
        localStorage.removeItem("property_slug_audit_resume");
        localStorage.removeItem("property_name_audit_resume");
        localStorage.removeItem("audit_name_audit_resume");
        localStorage.removeItem("description_audit_resume");
        setTimeout(
          () => this.props.history.push("/audits"),
          global.redirect_time
        );
        this.setState({ loading: false, isSubmit: false });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
  };
  async getImageForUpload() {
    if (this.state.backIndex === 2 && this.state.isBack === 0) {
      this.setState({ arrAllImages1: [] });
      return; // Exit early to avoid processing old images
    }
    if (this.state.isImage === true) {
      if (this.state.arrAllImages1.length > 0) {
        this.state.arrAllImages1.forEach((image, index) => {
          var categoryResultListData = this.state.inspectionData.all_category;
          for (var i in categoryResultListData) {
            var taskList = categoryResultListData[i].task_name;
            for (var k in taskList) {
              if (taskList[k].audit_task_id == image.auditTaskId) {
                image.task_slug = taskList[k].slug;
                this.onImageUpload(image);
                // if (index == this.arrAllImages.length - 1) {
                //   setTimeout(() => this.uploadImageAPI(image, true), 1500);
                // } else {
                //   setTimeout(() => this.uploadImageAPI(image, false), 1500);
                // }
              }
            }
          }
        });
      }
    }
  }

  localDataStorage = async (category) => {
    if (category != undefined || category != null) {
      await localStorage.setItem("auditCategory", JSON.stringify(category));
    }
  };
  render() {
    const { steps, activeStep } = this.state;
    return (
      <main>
        <div className="container-fluid">
          <div className="row row-xs">
            <div className="col-md-12">
              <div className="dashbaord-pageHeadertitle heading_alignment">
                <h3 className="flex-column">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h3>Audit</h3>
                    <Link
                      to={`/start-audit-list/${this.state.property_slug}/${this.state.checklist_slug}`}
                    >
                      <img src={back} alt="" />
                      Back
                    </Link>
                  </div>
                  <span>
                    {localStorage.getItem("propertyNameAuditPerform")}
                  </span>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-12 pl-0 pr-0"></div>
        </div>
        <div className="container-fluid">
          <div className="inspection_perform_section">
            <div className="row row-xs mg-t-20 justify-content-center">
              <div className="col-md-12 col-xl-12">
                <div className="box dashboardRightcard p-0">
                  <div className="steps">
                    <ul className="nav">
                      {steps.map((step, i) => {
                        return (
                          <li
                            key={i}
                            className={`${
                              activeStep.key === step.key ? "active" : ""
                            } ${step.isDone ? "done" : ""}`}
                          >
                            <div>
                              <span>{step.label}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="step-component pd-l-70 pd-r-70">
                    <form
                      id="insp_form"
                      method="post"
                      encType="multipart/form-data"
                    >
                      {activeStep.component()}
                      <div>
                        <div className="btn-component mg-t-5 mg-md-t-0">
                          <input
                            type="button"
                            value="Back"
                            onClick={this.handleBack}
                            disabled={steps[0].key === activeStep.key}
                          />
                          <button
                            className="gf-btn-lg-stroke mg-l-2"
                            type="button"
                            onClick={this.handleNext}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit
                              ? global.loader
                              : activeStep.key == "finalStep"
                              ? "Submit"
                              : "Continue"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default AuditPerform;
